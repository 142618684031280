<template>
  <div class="container">
    <el-row>
      <content-title :nav='nav'></content-title>
    </el-row>
    <el-row>
      <div style="margin-bottom:1rem">
        <label for="">标题</label>
        <el-input v-model="title" clearable></el-input>
        <label for="">状态</label>
        <el-select v-model="status" clearable>
          <el-option label="未生效" :value="1"></el-option>
          <el-option label="已生效" :value="2"></el-option>
        </el-select>
        <el-button @click="search()">搜索</el-button>
        <el-button size='small' type="primary" style="float:right" @click="addVisible = true">新增</el-button>
      </div>
      <el-table :data="banner_type_list" v-loading='is_loading'>
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="标题" prop="title"></el-table-column>
        <el-table-column label="类型" prop="type"></el-table-column>
        <el-table-column label="描述" prop="banner_descr"></el-table-column>
        <el-table-column label="创建时间" prop="create_time"></el-table-column>
        <el-table-column label="更新时间" prop="update_time"></el-table-column>
        <el-table-column label="状态" prop="status"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="small" type="primary" @click="openUpdate(scope.row)">编辑</el-button>
            <el-button size="small" type="warning" v-if="scope.row.status !=2" @click="changeStatus(scope.row,2)">上架</el-button>
            <el-button size="small" type="danger" v-else @click="changeStatus(scope.row,1)">下架</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :total="banner_type_total" :current-page="currentPage" @current-change='currentPageChange'>
      </el-pagination>
    </el-row>
    <el-dialog :visible='addVisible||updateVisible' :title="addVisible?'新增':'修改'" :before-close="handleClose">
      <el-form v-model="form" label-width="auto">
        <el-form-item label="标题">
          <el-input v-model="form.title" :placeholder="temp.title"></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-input v-model="form.type" :placeholder="temp.type"></el-input>
        </el-form-item>
        <el-form-item label="类型描述">
          <el-input v-model="form.banner_descr" :placeholder="temp.banner_descr"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-if="addVisible" @click="add()">确 定</el-button>
        <el-button type="primary" v-if="updateVisible" @click="update()">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: {
        firstNav: '广告管理中心',
        secondNav: '广告类型',
      },
      temp: {},
      form: {
        status: 2,
      },

      // 搜索参数
      title: null,
      status: null,
      currentPage: 1,
      banner_type_list: [],
      banner_type_total: 0,
      is_loading: true,
      addVisible: false,
      updateVisible: false,
    }
  },
  mounted() {
    this.getBannerTypeList()
  },
  methods: {
    getBannerTypeList() {
      this.is_loading = true
      let url = '/user/bannerType/queryManagerListPage'
      this.$axios
        .get(url, {
          params: {
            pageSize: 10,
            currentPage: this.currentPage,
            title: this.title ? this.title : null,
            status: this.status ? this.status : null,
          },
        })
        .then((res) => {
          this.banner_type_list = res.data.data.rows
          this.banner_type_total = res.data.data.total
          this.is_loading = false
        })
    },
    search() {
      this.is_loading = true
      this.getBannerTypeList()
    },
    currentPageChange(v) {
      this.currentPage = v
      this.getBannerTypeList()
    },
    add() {
      let url = '/user/bannerType/insert'
      this.$axios
        .post(url, {
          ...this.form,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: 'success', message: '添加成功!' })
            this.form = {
              status: 2,
            }
            this.getBannerTypeList()
            this.addVisible = false
          } else {
            this.$message({ type: 'error', message: res.data.message })
          }
        })
    },
    openUpdate(row) {
      this.temp = row
      this.form.id = row.id
      this.updateVisible = true
    },
    update() {
      let url = '/user/bannerType/update'
      this.$axios
        .post(url, {
          ...this.form,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: 'success', message: '修改成功!' })
            this.form = {
              status: 2,
            }
            this.updateVisible = false
            this.getBannerTypeList()
          } else {
            this.$message({ type: 'error', message: res.data.message })
          }
        })
    },
    changeStatus(row, status) {
      let url = '/user/bannerType/update'
      this.$axios
        .post(url, {
          id: row.id,
          status: status,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: 'success', message: '修改成功!' })
            this.getBannerTypeList()
          } else {
            this.$message({ type: 'error', message: res.data.message })
          }
        })
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.addVisible = false
          this.updateVisible = false
          done()
        })
        .catch((_) => {})
    },
  },
}
</script>

<style>
</style>